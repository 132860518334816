export default {
  // Global styles apply to _all_ inputs with matching section keys
  global: {
    wrapper: '',
    fieldset: 'border border-gray-50 rounded px-2 pb-1',
    help: 'text-t6 font-light text-gray-50 mt-1 dark:text-gray-30',
    inner:
      'focus-within:ring-blue-40 bg-white focus-within:ring-2 transition-shadow duration-300 ease-in ring-1 ring-primary rounded-input  formkit-disabled:cursor-not-allowed formkit-disabled:pointer-events-none text-t5 font-light',
    input:
      'formkit-disabled:opacity-50 appearance-none text-primary placeholder-gray-50 dark:text-white dark:bg-gray-60 focus:outline-0 focus:shadow-none rounded-input text-t5 font-light',
    legend: 'font-medium text-t5 text-light dark:text-gray-30',
    label: 'block mb-[1.25rem] font-medium text-h5 text-primary dark:text-white',
    loaderIcon: 'inline-flex items-center w-4 text-gray-60 animate-spin dark:text-gray-30',
    message: 'text-red-40 mt-1 mb-0 text-t6 font-light',
    messages: 'list-none p-0 mt-0 mb-0',
    outer: ' w-full',
    prefixIcon:
      'w-10 flex self-stretch grow-0 shrink-0 rounded-tl rounded-bl border-r border-gray-50 bg-white bg-gradient-to-b from-transparent to-gray-200 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
    suffixIcon:
      'w-7 pr-3 p-3 flex self-stretch grow-0 shrink-0 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto'
  },

  // Family styles apply to all inputs that share a common family
  'family:box': {
    decorator:
      'dark:bg-gray-60 block cursor-pointer relative h-[1rem] w-[1rem] mr-2 mt-[2px] rounded-sm bg-white from-transparent to-gray-50 ring-1 ring-gray-50 peer-checked:bg-primary dark:peer-checked:bg-white peer-focus-visible:ring-2 peer-focus-visible:ring-blue-40 text-transparent peer-checked:text-white formkit-disabled:bg-gray-30',
    decoratorIcon:
      'flex p-[2px] w-full h-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 dark:text-gray-30 formkit-icon',
    help: 'mb-2 mt-1.5 dark:text-gray-30',
    optionHelp: 'mb-2 mt-1.5 dark:text-gray-30',
    input: 'absolute w-0 h-0 overflow-hidden opacity-0 pointer-events-none peer select-none',
    inner:
      '$remove:formkit-disabled:bg-gray-30 dark:bg-transparent select-none $remove:ring-1 $remove:focus-within:ring-2 $remove:bg-white',
    label: '$reset text-t5 text-gray-60 mt-1 select-none dark:text-gray-30',
    wrapper: 'flex items-center mb-1 cursor-pointer'
  },
  'family:button': {
    input:
      '$reset inline-flex items-center bg-blue-600 text-white text-t5 font-normal py-3 px-6 rounded focus-visible:outline-2 focus-visible:outline-blue-600 focus-visible:outline-offset-2 formkit-disabled:bg-gray-50 formkit-loading:before:w-4 formkit-loading:before:h-4 formkit-loading:before:mr-2 formkit-loading:before:border formkit-loading:before:border-2 formkit-loading:before:border-r-transparent formkit-loading:before:rounded-3xl formkit-loading:before:border-white formkit-loading:before:animate-spin',
    wrapper: 'mb-1',
    prefixIcon: '$reset block w-4 -ml-2 mr-2 stretch',
    suffixIcon: '$reset block w-4 ml-2 stretch'
  },
  'family:dropdown': {
    dropdownWrapper: 'my-2 w-full shadow-lg rounded [&::-webkit-scrollbar]:hidden',
    emptyMessageInner:
      'flex items-center justify-center text-t5 p-2 text-center w-full text-gray-50[&>span]:mr-3 [&>span]:ml-0 dark:text-gray-30',
    inner:
      'max-w-md relative flex ring-1 ring-gray-50 focus-within:ring-blue-500 focus-within:ring-2 rounded mb-1 formkit-disabled:focus-within:ring-gray-50 formkit-disabled:focus-within:ring-1 [&>span:first-child]:focus-within:text-blue-500',
    input: 'w-full px-[1.25rem] py-[0.75rem]',
    listbox: 'bg-white shadow-lg rounded overflow-hidden',
    listboxButton: 'flex w-12 self-stretch justify-center mx-auto',
    listitem:
      'pl-7 relative hover:bg-primary hover:text-white aria-selected:bg-white aria-selected:text-primary data-[is-active="true"]:bg-primary data-[is-active="true"]:text-white data-[is-active="true"]:aria-selected:bg-primary data-[is-active="true"]:aria-selected:text-white',
    loaderIcon: 'ml-auto',
    loadMoreInner:
      'flex items-center justify-center text-t5 p-2 text-center w-full text-blue-500 formkit-loading:text-gray-50 dark:text-gray-30 cursor-pointer [&>span]:mr-3 [&>span]:ml-0',
    option: 'p-2.5',
    optionLoading: 'text-gray-50 dark:text-gray-30',
    placeholder: 'p-2.5 text-gray-50 dark:text-gray-30',
    selector: 'flex w-full justify-between items-center [&u]',
    selectedIcon: 'block absolute top-1/2 left-2 w-3 -translate-y-1/2',
    selectIcon: 'flex box-content w-4 px-2 self-stretch grow-0 shrink-0 [&>svg]:w-[1em]'
  },
  'family:text': {
    inner: 'flex items-center',
    input:
      'w-full px-[1.25rem] py-[0.75rem] border-none text-base text-gray-60 placeholder-gray-50 dark:text-gray-30'
  },
  'family:date': {
    inner: 'flex items-center',
    input:
      'w-full px-[1.25rem] py-[0.75rem] border-none text-gray-60 placeholder-gray-50 dark:text-gray-30'
  },

  // Specific styles apply only to a given input type
  color: {
    inner:
      'flex max-w-[5.5em] w-full formkit-prefix-icon:max-w-[7.5em] formkit-suffix-icon:formkit-prefix-icon:max-w-[10em]',
    input:
      '$reset appearance-none w-full cursor-pointer border-none rounded p-0 m-0 bg-transparent [&::-webkit-color-swatch-wrapper]:p-0 [&::-webkit-color-swatch]:border-none',
    suffixIcon: 'min-w-[2.5em] pr-0 pl-0 m-auto'
  },
  file: {
    fileItem: 'flex items-center text-gray-60 mb-1 last:mb-0 dark:text-gray-30',
    fileItemIcon: 'w-4 mr-2 shrink-0',
    fileList: 'shrink grow peer !px-0 !py-0 formkit-multiple:data-[has-multiple="true"]:mb-6',
    fileName: 'break-all grow text-ellipsis',
    fileRemove:
      '!leading-none relative z-[2] ml-auto text-[0px] hover:text-red-40 pl-2 peer-data-[has-multiple=true]:text-t6 peer-data-[has-multiple=true]:underline peer-data-[has-multiple=true]:text-gray-60 peer-data-[has-multiple=true]:ml-4 peer-data-[has-multiple=true]:mb-2 formkit-multiple:bottom-[0.15em] formkit-multiple:pl-0 formkit-multiple:ml-0 formkit-multiple:left-[2em] formkit-multiple:formkit-prefix-icon:left-[3.75em]',
    fileRemoveIcon: 'block w-3 relative z-[2]',
    inner:
      'relative !cursor-pointer formkit-multiple:[&>button]:absolute px-[1.25rem] py-[0.75rem] ring-1 ring-primary !rounded-input focus:!ring-primary focus:!ring-1 focus-within:!ring-primary focus-within:!ring-1',
    input:
      '!cursor-pointer text-transparent absolute top-0 right-0 left-0 bottom-0 opacity-0 z-[2] !px-0 !py-0',
    noFiles: 'flex w-full items-center text-gray-60 cursor-pointer dark:text-gray-30 !px-0 !py-0',
    noFilesIcon: 'w-4 mr-2 cursor-pointer'
  },
  radio: {
    decorator: 'rounded-full',
    decoratorIcon: 'w-5 p-[5px]'
  },
  range: {
    inner: '$reset flex items-center',
    input: '$reset w-full mb-1 h-2 p-0 rounded-full',
    prefixIcon:
      '$reset w-4 mr-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
    suffixIcon:
      '$reset w-4 ml-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto'
  },
  select: {
    inner:
      'flex relative max-w-md items-center rounded mb-1 ring-1 ring-gray-50 focus-within:ring-blue-500 focus-within:ring-2 [&>span:first-child]:focus-within:text-blue-500',
    input:
      'w-full pl-3 pr-8 py-2 border-none text-base text-gray-60 dark:text-gray-30 placeholder-gray-50 formkit-multiple:p-0 data-[placeholder="true"]:text-gray-50 formkit-multiple:data-[placeholder="true"]:text-inherit',
    selectIcon:
      'flex p-[3px] shrink-0 w-5 mr-2 -ml-[1.5em] h-full pointer-events-none [&>svg]:w-[1em]',
    option: 'formkit-multiple:p-3 formkit-multiple:text-t5 text-gray-60 dark:text-gray-30'
  },
  textarea: {
    inner:
      'flex max-w-md rounded mb-1 ring-1 ring-gray-50 focus-within:ring-blue-500 [&>label:first-child]:focus-within:text-blue-500',
    input:
      'block w-full h-32 px-3 py-3 border-none text-base text-gray-60 dark:text-gray-30 placeholder-gray-50 focus:shadow-outline'
  },

  // PRO input styles
  taglist: {
    inner: '!rounded-[0.625rem] $remove:max-w-md',
    input: '!w-[0%] !grow !px-2',
    removeSelection: 'mx-1 self-center text-primary leading-none',
    tag: 'flex items-center my-1 p-1 bg-secondary-1 text-t5 font-light rounded-[0.31rem]',
    tagWrapper:
      'mr-1 focus:outline-none focus:text-white [&>div]:focus:bg-primary [&>div>button]:focus:text-white',
    tagLabel: 'pl-2 pr-1',
    tags: 'px-2 flex items-center flex-wrap w-full'
  },
  toggle: {
    label: 'cursor-pointer !mb-0 select-none',
    altLabel: 'block w-full mb-1 font-medium text-t5',
    inner: '$reset inline-block mr-2',
    input: 'peer absolute opacity-0 pointer-events-none',
    innerLabel: 'cursor-pointer !mb-0 select-none',
    thumb: 'relative left-0 aspect-square rounded-full transition-all w-5 bg-white',
    track:
      'cursor-pointer p-0.5 min-w-[3em] relative rounded-full transition-all bg-gray-50 peer-checked:bg-green-20 peer-checked:[&>div:last-child]:left-full peer-checked:[&>div:last-child]:-translate-x-full peer-checked:[&>div:first-child:not(:last-child)]:left-0 peer-checked:[&>div:first-child:not(:last-child)]:translate-x-0',
    valueLabel: 'font-medium text-t5 mb-0',
    wrapper: 'flex flex-wrap items-center mb-0'
  }
}
